import React from "react";
import { Modal } from "antd";
import { message } from "antd";
import Http from "../../../../utils/Http";
import axios from "axios";
import "./index.less";

// new 教程弹窗
const CourseModal = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);
  const close = () => {
    setVisible(false);
  };
  const show = () => {
    setVisible(true);
  };
  React.useImperativeHandle(ref, () => {
    return {
      show: show,
      close: close,
    };
  });

  const end = (e) => {
    e.stopPropagation();
    close();
    setCourseIndex(0);
  };

  const [courseIndex, setCourseIndex] = React.useState(0);
  const [courseList, setCourseList] = React.useState([
    {
      id: 1,
      bg: require("../../../../assets/images/course/img_tutorials2.png"),
      next: "",
    },
    {
      id: 2,
      bg: require("../../../../assets/images/course/img_tutorials12.png"),
      next: "111",
    },
  ]);

  return (
    <Modal
      title={null}
      visible={visible}
      closable={false}
      footer={null}
      onCancel={close}
      className="ap_modal_course"
    >
      <div
        className="ap_modal_course_box"
        style={{
          backgroundImage: `url(${courseList[courseIndex].bg})`,
        }}
        onClick={(e) => {
          if (
            courseList &&
            courseList?.length > 0 &&
            courseIndex < courseList?.length - 1 &&
            courseList[courseIndex]?.next === ""
          ) {
            setCourseIndex(courseIndex + 1);
          } else {
            end(e);
          }
        }}
      >
        <div className="ap_modal_course_box_btn" onClick={end}>
          跳过
        </div>
      </div>
    </Modal>
  );
});

export default CourseModal;
