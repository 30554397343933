import React from "react";
import "./index.less";
import axios from "axios";
import { baseUrl1, baseUrl2 } from "../../config";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { setToken } from "../../store/app";
import { useNavigate } from "react-router-dom";
import Progress from "./components/Progress";
import Turret from "./components/Turret";
import RewardModel from "./components/RewardModel";
import PlayRulesModal from "./components/PlayRulesModal";
import ConsumeModal from "./components/ConsumeModal";
import PartyModal from "./components/PartyModal";
import ShowGameEndModel from "./components/ShowGameEndModel";
import DaoJiShiModel from "./components/DaoJiShiModel";
import CourseModal from "./components/CourseModal";
import GameMainModel from "./components/GameMainModel";
import GetIntegralModal from "./components/GetIntegralModal";

import Tip from "./components/Tip";
import TipProgress from "./components/TipProgress";
import Animal from "./components/Animal";

import moment from "moment";
import { useInterval } from "usehooks-ts";
import MonsterBlast from "./components/MonsterBlast";
import { setUserId } from "../../store/user";

// // 动物运动帧
// const times = [0.98, 2.9, 4.04, 4.74, 11.19, 13.47, 14.4, 16.276, 20];
// const v = 8.75;
// // 动物移动
// function computePositons(time) {
//   if (time < times[0]) {
//     return {
//       left: 9 + time * v,
//       top: 39,
//       isRate: false, // 图片方向
//       index: 0,
//     };
//   } else if (time < times[1]) {
//     return {
//       left: 17.6,
//       top: 39 + (time - times[0]) * v,
//       isRate: false,

//       index: 1,
//     };
//   } else if (time < times[2]) {
//     return {
//       left: 17.6,
//       top: 55.6 + (time - times[1]) * v,
//       isRate: false,
//       index: 2,
//     };
//   } else if (time < times[3]) {
//     return {
//       left: 17.6,
//       top: 65.6 + (time - times[2]) * v,
//       isRate: false,
//       index: 2,
//     };
//   } else if (time < times[4]) {
//     return {
//       left: 17.6 + (time - times[3]) * v,
//       top: 71.53,
//       isRate: false,
//       index: 3,
//     };
//   } else if (time < times[5]) {
//     return {
//       left: 73.6,
//       top: 71.53 - (time - times[4]) * v,
//       isRate: false,
//       index: 4,
//     };
//   } else if (time < times[6]) {
//     return {
//       left: 73.6 - (time - times[5]) * v,
//       top: 52.9,
//       isRate: true,
//       index: 5,
//     };
//   } else if (time < times[7]) {
//     return {
//       left: 64.8,
//       top: 53.9 - (time - times[6]) * v,
//       isRate: false,
//       index: 6,
//     };
//   } else {
//     return {
//       left: 64.8 + (time - times[7]) * v,
//       top: 39.16,
//       isRate: false,
//       index: 7,
//     };
//   }
// }

const times = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
]; // 动物运动帧
const speed = {
  v1: 16,
  x1: 5,
  v2: 6,
  x2: 6,
  v3: 14, // 16
  x3: 5,
  v4: 6,
  x4: 7,
  v5: 18,
  x5: 4,
  v_1: 8,
  x_1: 2,
  v_2: 4.74,
  x_2: 6.65,
}; // 动物运动速度
function computePositons(time) {
  if (time < times[0]) {
    // 当前时间小于第一帧时
    return {
      left: -22 + time * speed.v1,
      top: -18 + time * speed.x1,
      isRate: false, // 图片方向
      index: 0,
    };
  } else if (time < times[1]) {
    return {
      left: -22 + time * speed.v1,
      top: -18 + time * speed.x1,
      isRate: false,
      index: 0,
    };
  } else if (time < times[2]) {
    return {
      left: -22 + time * speed.v1,
      top: -18 + time * speed.x1,
      isRate: false,
      index: 0,
    };
  } else if (time < times[3]) {
    return {
      left: -22 + time * speed.v1,
      top: -18 + time * speed.x1,
      isRate: false,
      index: 0,
    };
  } else if (time < times[4]) {
    return {
      left: -22 + time * speed.v1,
      top: -18 + time * speed.x1,
      isRate: false,
      index: 0,
    };
  } else if (time < times[5]) {
    return {
      left: -22 + time * speed.v1, // 第一段结束
      top: -18 + time * speed.x1,
      isRate: false,
      index: 1,
    };
  } else if (time < times[6]) {
    return {
      left: -22 + times[5] * speed.v1 + -(time - times[5]) * speed.v2, // 第二段结束
      top: -18 + times[5] * speed.x1 + (time - times[5]) * speed.x2,
      isRate: true,
      index: 1,
    };
  } else if (time < times[7]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 1,
    };
  } else if (time < times[8]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 2,
    };
  } else if (time < times[9]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 2,
    };
  } else if (time < times[10]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 3,
    };
  } else if (time < times[11]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3, // 第三段结束
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 4,
    };
  } else if (time < times[12]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (time - times[11]) * speed.v4,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (time - times[11]) * speed.x4,
      isRate: false,
      index: 4,
    };
  } else if (time < times[13]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (time - times[11]) * speed.v4, // 第四段结束
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (time - times[11]) * speed.x4,
      isRate: false,
      index: 5,
    };
  } else if (time < times[14]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 5,
    };
  } else if (time < times[15]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 6,
    };
  } else if (time < times[16]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  } else if (time < times[17]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  } else if (time < times[18]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  } else {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5, // 第五段结束
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  }
} // 动物移动1
function computePositons2(time) {
  if (time < times[0]) {
    // 当前时间小于第一帧时
    return {
      left: 4 + time * speed.v_1,
      top: 17 + time * speed.x_1,
      isRate: false, // 图片方向
      index: 0,
    };
  } else if (time < times[1]) {
    return {
      left: 4 + time * speed.v_1,
      top: 17 + time * speed.x_1,
      isRate: false,
      index: 0,
    };
  } else if (time < times[2]) {
    return {
      left: 4 + time * speed.v_1 + (time - times[1]) * speed.v_2,
      top: 17 + time * speed.x_1 - (time - times[1]) * speed.x_2,
      isRate: false,
      index: 0,
    };
  } else if (time < times[3]) {
    return {
      left: 4 + time * speed.v_1 + (time - times[1]) * speed.v_2,
      top: 17 + time * speed.x_1 - (time - times[1]) * speed.x_2,
      isRate: false,
      index: 0,
    };
  } else if (time < times[4]) {
    return {
      left: 4 + time * speed.v_1 + (time - times[1]) * speed.v_2,
      top: 17 + time * speed.x_1 - (time - times[1]) * speed.x_2,
      isRate: false,
      index: 0,
    };
  } else if (time < times[5]) {
    return {
      left: -22 + time * speed.v1, // 第一段结束
      top: -18 + time * speed.x1,
      isRate: false,
      index: 1,
    };
  } else if (time < times[6]) {
    return {
      left: -22 + times[5] * speed.v1 + -(time - times[5]) * speed.v2, // 第二段结束
      top: -18 + times[5] * speed.x1 + (time - times[5]) * speed.x2,
      isRate: true,
      index: 1,
    };
  } else if (time < times[7]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 1,
    };
  } else if (time < times[8]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 2,
    };
  } else if (time < times[9]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 2,
    };
  } else if (time < times[10]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 3,
    };
  } else if (time < times[11]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(time - times[6]) * speed.v3, // 第三段结束
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (time - times[6]) * speed.x3,
      isRate: true,
      index: 4,
    };
  } else if (time < times[12]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (time - times[11]) * speed.v4,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (time - times[11]) * speed.x4,
      isRate: false,
      index: 4,
    };
  } else if (time < times[13]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (time - times[11]) * speed.v4, // 第四段结束
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (time - times[11]) * speed.x4,
      isRate: false,
      index: 5,
    };
  } else if (time < times[14]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 5,
    };
  } else if (time < times[15]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 6,
    };
  } else if (time < times[16]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  } else if (time < times[17]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  } else if (time < times[18]) {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5,
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  } else {
    return {
      left:
        -22 +
        times[5] * speed.v1 +
        -(times[6] - times[5]) * speed.v2 +
        -(times[11] - times[6]) * speed.v3 +
        (times[13] - times[11]) * speed.v4 +
        (time - times[13]) * speed.v5, // 第五段结束
      top:
        -18 +
        times[5] * speed.x1 +
        (times[6] - times[5]) * speed.x2 +
        (times[11] - times[6]) * speed.x3 +
        (times[13] - times[11]) * speed.x4 +
        (time - times[13]) * speed.x5,
      isRate: false,
      index: 7,
    };
  }
} // 动物移动2

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// 游戏
const Game = () => {
  const paramsStr = window.location.search;
  const params = new URLSearchParams(paramsStr);
  const token = params.get("token");

  const navigate = useNavigate();
  const storeToken = useSelector((state) => state.App.token);
  const userId = useSelector((state) => state.User.userId);
  const dispatch = useDispatch();

  const [initLoading, setInitLoading] = React.useState(true);

  const [count, setCount] = React.useState(0); // 积分
  const [shengDou, setShengDou] = React.useState(null); // 深豆

  const [bossName, setBossName] = React.useState("小动物"); // 出场动物类型名称

  const [gameState, setGameState] = React.useState("loading"); // 游戏状态
  const [endTime, setEndTime] = React.useState(0); // 派对倒计时 30s
  const [loadingTime, setLoadingTime] = React.useState(false);

  const [dinosaur, setDinosaur] = React.useState({}); // 动物对象
  const [bossState, setBossState] = React.useState({}); // boss状态
  const [award, setAward] = React.useState({}); // 奖品对象
  const [inGameTime, setInGameTime] = React.useState(0);
  const [inGamePlaying, setInGamePlaying] = React.useState(false);
  const [waitGameEndTime, setWaitGameEndTime] = React.useState(0); // 等待游戏结束剩余时间
  const [waitGameEndTimePlaying, setWaitGameEndTimePlaying] =
    React.useState(false);
  const [waitMaxTime, setWaitMaxTime] = React.useState(0); // 等待游戏结束时间

  const [inGameEndPlaying, setInGameEndPlaying] = React.useState(false);
  const [inGameEndTime, setInGameEndTime] = React.useState(0);

  const [turretList, setTurretList] = React.useState([]); // 食物列表
  const [turretStatusList, setTurretStatusList] = React.useState([]); // 食物状态列表

  const [isOpenGameMainModal, setIsOpenGameMainModal] = React.useState(false); // 游戏蒙版
  const gameMainModalRef = React.useRef(null); // 游戏蒙版
  const getIntegralModal = React.useRef(null); // 获取积分
  const goodsModelRef = React.useRef(null); // 奖励提示
  const showGameEndRef = React.useRef(null); // 游戏结束
  const monsterBlastRef = React.useRef(null); // 小动物消失
  const playRulesModalRef = React.useRef(null); // 玩法介绍
  const consumeModelRef = React.useRef(null); //消息记录
  const partyModalRef = React.useRef(null); //派对记录
  const courseModalRef = React.useRef(null); // 查看教程
  const daoJiShiModalRef = React.useRef(null); // 倒计时
  const [harvestSequence, setHarvestSequence] = React.useState(0);

  // 游戏结束
  const gameEnd = () => {
    // await monsterBlastRef.current?.show();
    setTimeout(() => {
      if (award?.user_id == userId) goodsModelRef.current?.show();
      setInGameTime(0);
    }, 1000);

    setTimeout(() => {
      goodsModelRef.current?.close();
    }, 5000);
  };

  const { top, left, isRate, index } = React.useMemo(() => {
    // return computePositons(inGameTime);
    // return computePositons2(inGameTime);
    if (dinosaur.category === 1) return computePositons2(inGameTime);
    return computePositons(inGameTime);
  }, [inGameTime, bossName]);

  // 获取积分
  const getUserWallet = async () => {
    try {
      const { data } = await axios({
        method: "post", //请求方法
        data: {
          token: token,
        },
        url: `${baseUrl2}/api/v1/user/walletList`,
      });
      if (data?.status === 10000) {
        const wallet = data?.data?.find((item) => item?.asset === "jifen");
        const shengDouWallet = data?.data?.find(
          (item) => item?.asset === "shengdou"
        );
        setCount(wallet?.free_number ?? 0);
        setShengDou(shengDouWallet?.free_number ?? 0);

        dispatch(setUserId(wallet?.user_id ?? 0));
      } else if (data?.status === 10001) {
        // navigate("/?token=" + token);
        setIsOpenGameMainModal(true);
        // message.error("游戏加载失败");
      } else {
        // navigate("/?token=" + token);
        // message.error(data?.message);
        setIsOpenGameMainModal(true);
      }
    } catch (error) {
      // message.error("网络错误，请检查网络设置");
      setIsOpenGameMainModal(true);
    } finally {
    }
  };

  // 获取Boss状态
  const getBossStatus = async () => {
    try {
      const { data } = await axios({
        method: "post", //请求方法
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          token: token,
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/bossStatus`,
      });
      if (data?.status === 10000) {
        setBossState(data?.data);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置3");
    } finally {
    }
  };

  const formateData = (data) => {
    const duration = moment(data?.result_time).diff(moment(), "second");
    const duration_end = moment().diff(moment(data?.end_time), "second");

    setTurretStatusList(data?.location);
    setDinosaur(data?.dinosaur_info);
    setHarvestSequence(data?.harvest_sequence);
    if (duration > 0) {
      if (duration > 3) {
        console.log("游戏准备阶段");
        setGameState("loading");
        setEndTime(duration - 3);
        setLoadingTime(true);
      } else {
        if (data?.dinosaur_info.id === 0) {
          // console.log("游戏错误");
          setGameState("GameError");
          setTimeout(() => {
            setInGameEndPlaying(false);
            setInGameEndTime(0);
            getTurretList();
            getNowGame();
            getBossStatus();
          }, (20 + duration) * 1000);
        } else {
          setTimeout(() => {
            console.log("游戏开始");
            setGameState("inGame");
            setInGameTime(0);
            setInGamePlaying(true);
          }, duration * 1000);
        }
      }
    } else if (duration_end > 0) {
      setInGameEndTime(10 - duration_end);
      setInGameEndPlaying(true);
      setGameState("GameEnd");
    } else {
      console.log("游戏进行阶段");
      const res = moment().diff(moment(data?.result_time), "second");

      if (data?.dinosaur_info.id === 0) {
        setGameState("GameError");
        console.log("游戏错误");

        setTimeout(() => {
          setInGameEndPlaying(false);
          setInGameEndTime(0);
          getTurretList();
          // getNowGame(true);
          getBossStatus();
        }, (20 - res) * 1000);
      } else {
        setGameState("inGame");
        setInGameTime(res);
        setInGamePlaying(true);
      }
    }
  };

  // 获取食物列表
  const getTurretList = async () => {
    try {
      const { data } = await axios({
        method: "post", //请求方法
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          token: token,
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/turretList`,
      });

      if (data?.status === 10000) {
        setTurretList(data?.data);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
    }
  };

  // 获取新的游戏
  const getNowGame = async (is_next, gameState) => {
    try {
      const { data } = await axios({
        method: "post",
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: {
          is_next: gameState === "inGame" ? 0 : is_next ? 1 : 0,
          user_id: userId,
          // token: token,
        },
        url: `${baseUrl1}/api/v1/dinosaur_paradise/nowGame`,
      });

      if (data?.status === 10000) {
        console.log("data", data?.data);

        if (gameState === "inGame") {
          setTurretStatusList(data?.data?.game?.location);
          setDinosaur(data?.data?.game?.dinosaur_info);
          setHarvestSequence(data?.data?.game?.harvest_sequence);
          if (data?.data?.game?.dinosaur_info?.id !== 0) {
            setGameState("inGame");
            setInGameTime(0);
            setInGamePlaying(true);
          } else {
            getTurretList();
            getNowGame(true, "inGame");
            getBossStatus();
          }
        } else if (is_next) {
          setTurretStatusList(data?.data?.game?.location);
          setDinosaur(data?.data?.game?.dinosaur_info);
          setHarvestSequence(data?.data?.game?.harvest_sequence);
        } else {
          formateData(data?.data?.game);
        }
        setAward(data.data?.award);
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置2");
    } finally {
    }
  };

  //等待游戏结束
  const waitGameEnd = (start_time) => {
    setInGamePlaying(false);

    setGameState("WaitGameEnd");

    monsterBlastRef.current?.show();
    gameEnd();

    let waitTime = 20 - start_time;

    setWaitGameEndTimePlaying(true);
    setWaitGameEndTime(waitTime);
    setWaitMaxTime(waitTime);
  };

  // token
  React.useEffect(() => {
    // if (token && token !== storeToken) {
    if (token && token !== "" && token !== storeToken) {
      dispatch(setToken(token));
    }
  }, [dispatch, storeToken, token]);

  // 购买食物投喂区间、中奖、没中奖
  React.useEffect(() => {
    if (
      inGameTime > 4.9 &&
      inGameTime < times[4] &&
      award?.goods_id !== 0 &&
      turretList[0]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 7.9 &&
      inGameTime < times[7] &&
      award?.goods_id !== 0 &&
      turretList[1]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 9.9 &&
      inGameTime < times[9] &&
      award?.goods_id !== 0 &&
      turretList[2]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 10.9 &&
      inGameTime < times[10] &&
      award?.goods_id !== 0 &&
      turretList[3]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 12.9 &&
      inGameTime < times[12] &&
      award?.goods_id !== 0 &&
      turretList[4]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 14.9 &&
      inGameTime < times[14] &&
      award?.goods_id !== 0 &&
      turretList[5]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 15.9 &&
      inGameTime < times[15] &&
      award?.goods_id !== 0 &&
      turretList[6]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (
      inGameTime > 16.9 &&
      inGameTime < 19.9 &&
      award?.goods_id !== 0 &&
      turretList[7]?.sequence === harvestSequence
    ) {
      waitGameEnd(inGameTime, award);
    } else if (inGameTime >= 19.9) {
      showGameEndRef.current?.show();
    }
  }, [inGameTime, award, turretList, harvestSequence]);

  // 派对倒计时
  useInterval(
    () => {
      let res = endTime - 1;
      if (res <= 0) {
        setLoadingTime(false);
        setGameState("gameLoading");
        daoJiShiModalRef.current.show();
        //获取游戏结果
        setTimeout(() => {
          console.log("获取游戏结果");
          daoJiShiModalRef.current.close();
          getTurretList();
          getNowGame(true, "inGame");
          getBossStatus();
        }, 3000);

        setEndTime(0);
      } else {
        setEndTime(res);
      }
    },
    loadingTime ? 1000 : null
  );
  // 游戏期间
  useInterval(
    () => {
      let res = inGameTime + 0.016;

      if (res >= 20) {
        setInGamePlaying(false);
        setInGameTime(0);

        setGameState("GameEnd");
        setInGameEndTime(7);
        setInGameEndPlaying(true);

        setTimeout(() => {
          getTurretList();
          getNowGame(true);
          getBossStatus();
        }, 1000);
      } else {
        setInGameTime(res);
      }
    },
    inGamePlaying ? 16 : null
  );
  // 即将开始下一轮
  useInterval(
    () => {
      let res = inGameEndTime - 1;
      if (res <= 0) {
        setInGameEndPlaying(false);
        setInGameEndTime(0);
        if (Number(bossState?.accumulative) >= Number(bossState?.consume)) {
          console.log("boss");
          setBossName("boss");
        } else {
          console.log("小动物");
          setBossName("小动物");
        }
        setGameState("loading");
        setEndTime(30); // 派对倒计时 30s
        setLoadingTime(true);
      } else {
        setInGameEndTime(res);
      }
    },
    inGameEndPlaying ? 1000 : null
  );
  // 等待比赛结束
  useInterval(
    () => {
      console.log("waitGameEndTime");
      let res = waitGameEndTime - 1;
      if (res <= 0) {
        setWaitGameEndTimePlaying(false);
        setWaitGameEndTime(0);
        setWaitMaxTime(0);

        setGameState("GameEnd");
        setInGameEndTime(7);
        setInGameEndPlaying(true);

        setTimeout(() => {
          getTurretList();
          getNowGame(true);
          getBossStatus();
        }, 1000);
      } else {
        setWaitGameEndTime(res);
      }
    },
    waitGameEndTimePlaying ? 1000 : null
  );
  // 初始化
  const init = async () => {
    try {
      await Promise.all([
        getUserWallet(),
        getTurretList(),
        getNowGame(),
        getBossStatus(),
      ]);
    } catch (error) {
      setIsOpenGameMainModal(true);
    } finally {
      setInitLoading(false);
    }
  };
  // 后台切回刷新代码
  document.onvisibilitychange = async () => {
    console.log("刷新");
    await initGameFun();
  };
  const initGameFun = async () => {
    await init();
  };
  React.useEffect(() => {
    initGameFun();
  }, []);

  if (initLoading) {
    return (
      <div className="load-container">
        <div className="loading">
          <Spin indicator={antIcon} />
        </div>
      </div>
    );
  }

  return (
    <div className="game">
      <header className="game_header">
        <div className="brand">
          <h1 className="title">动物派对</h1>
          <img
            className="rule"
            onClick={() => {
              playRulesModalRef.current.show();
            }}
            src={require("../../assets/icon_description2.png")}
            alt=""
          />
        </div>
        <div className="action">
          <div
            className="action_item"
            onClick={() => {
              consumeModelRef.current.show();
            }}
          >
            消费记录
          </div>
          <div className="action_item divider">|</div>
          <div
            className="action_item"
            onClick={() => {
              partyModalRef.current.show();
            }}
          >
            派对记录
          </div>
        </div>
      </header>
      <GameMainModel
        open={isOpenGameMainModal}
        setOpen={setIsOpenGameMainModal}
        init={init}
      />
      <main className="game_main">
        {/* 余额 */}
        <div
          className="sum"
          onClick={() => {
            getIntegralModal.current.show();
          }}
        >
          <img
            className="icon"
            src={require("../../assets/icon.png")}
            alt=""
          />
          <span>{count}</span>
          <img
            className="add"
            src={require("../../assets/add2.png")}
            alt=""
          />
        </div>
        {/* Boss屋 */}
        <Progress bossState={bossState} />
        {/* 获取积分弹窗 */}
        <GetIntegralModal
          ref={getIntegralModal}
          count={count}
          shengDou={shengDou}
          getUserWallet={getUserWallet}
        />
        {/* 玩法介绍弹窗 */}
        <PlayRulesModal course={courseModalRef} ref={playRulesModalRef} />
        {/* 教程弹窗 */}
        <CourseModal ref={courseModalRef} />
        {/* 消息记录弹窗 */}
        <ConsumeModal ref={consumeModelRef} />
        {/* 派对记录弹窗 */}
        <PartyModal ref={partyModalRef} />
        {/* 奖励提示弹窗 */}
        <RewardModel ref={goodsModelRef} award={award} dinosaur={dinosaur} />
        {/* 游戏结束弹窗 */}
        <ShowGameEndModel ref={showGameEndRef} />
        {/* 倒计时弹窗 */}
        <DaoJiShiModel ref={daoJiShiModalRef} />
        {/* 游戏开始时间 进度条 */}
        <TipProgress
          endTime={endTime}
          gameState={gameState}
          inGameEndTime={inGameEndTime}
          inGameTime={inGameTime}
          waitGameEndTime={waitGameEndTime}
          waitMaxTime={waitMaxTime}
          bossName={bossName}
          init={init}
        />
        {/* 小动物 */}
        {gameState === "inGame" && (
          <Animal
            style={{
              top: top + "%",
              left: left + "%",
              zIndex: dinosaur.category ? 300 : 8,
            }}
            isRate={isRate}
            dinosaur={dinosaur}
            // turret={turretList[index]}
            turret={turretStatusList[index]}
          />
        )}
        {/* 动物嗝屁时爆炸的烟雾 */}
        <MonsterBlast
          ref={monsterBlastRef}
          style={{
            top: top + "%",
            left: left + "%",
          }}
        />
        {/* 食物 */}
        <>
          {/* 食物1 */}
          <Turret
            style={{
              left: "66%",
              bottom: "78%",
            }}
            gameState={gameState}
            getBossStatus={getBossStatus}
            getUserWallet={getUserWallet}
            item={turretList[0] ?? {}}
            index='1'
            itemStatus={turretStatusList[0] ?? {}}
          />
          {/* 食物2 */}
          <Turret
            style={{
              left: "72%",
              bottom: "48%",
            }}
            gameState={gameState}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            item={turretList[1] ?? {}}
            itemStatus={turretStatusList[1] ?? {}}
            index='2'
            sequence={1}
          />
          {/* 食物3 */}
          <Turret
            style={{
              left: "47%",
              bottom: "39%",
              zIndex: 300,
            }}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            gameState={gameState}
            item={turretList[2] ?? {}}
            index='3'
            itemStatus={turretStatusList[2] ?? {}}
            sequence={2}
          />
          {/* 食物4 */}
          <Turret
            style={{
              left: "8%",
              bottom: "50%",
            }}
            gameState={gameState}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            item={turretList[3] ?? {}}
            index='4'
            itemStatus={turretStatusList[3] ?? {}}
            sequence={3}
          />
          {/* 食物5 */}
          <Turret
            style={{
              left: "22%",
              bottom: "32%",
            }}
            gameState={gameState}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            item={turretList[4] ?? {}}
            index='5'
            itemStatus={turretStatusList[4] ?? {}}
            sequence={4}
          />
          {/* 食物6 */}
          <Turret
            style={{
              left: "21%",
              bottom: "9%",
              zIndex: 300,
            }}
            gameState={gameState}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            item={turretList[5] ?? {}}
            index='6'
            itemStatus={turretStatusList[5] ?? {}}
            sequence={5}
          />
          {/* 食物7 */}
          <Turret
            style={{
              left: "49%",
              bottom: "20%",
            }}
            gameState={gameState}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            item={turretList[6] ?? {}}
            index='7'
            itemStatus={turretStatusList[6] ?? {}}
            sequence={6}
          />
          {/* 食物8 */}
          <Turret
            style={{
              left: "76%",
              bottom: "17%",
            }}
            gameState={gameState}
            getUserWallet={getUserWallet}
            getBossStatus={getBossStatus}
            item={turretList[7] ?? {}}
            index='8'
            itemStatus={turretStatusList[7] ?? {}}
            sequence={7}
          />
        </>
        <img
          className="shitou"
          src={require("../../assets/images/game/shitou.png")}
          alt=""
        />
      </main>
    </div>
  );
};

export default Game;
