import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { setToken } from "../../store/app";
import { setUserId } from "../../store/user";
import axios from "axios";
import { baseUrl1, baseUrl2 } from "../../config";
import "./index.less";

const GameIndex = () => {
  const paramsStr = window.location.search;
  const params = new URLSearchParams(paramsStr);
  const token = params.get("token");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 获取积分
  const getUserWalletList = async () => {
    try {
      const { data } = await axios({
        method: "post", //请求方法
        headers: {
          token: token,
        },
        data: {
          token: token,
        },
        url: `${baseUrl2}/api/v1/user/walletList`,
      });

      if (data?.status === 10000) {
        const wallet = data?.data?.find((item) => item?.asset === "jifen");
        dispatch(setUserId(wallet?.user_id ?? 0));
        setTimeout(() => {
          navigate(`/animal?token=${token}`);
        }, 2000);
      } else if (data?.status === 10001) {
        message.error("游戏加载失败");
      } else {
        message.error(data?.message);
      }
    } catch (error) {
      message.error("网络错误，请检查网络设置");
    } finally {
    }
  };

  useEffect(() => {
    if (token && token !== "") {
      dispatch(setToken(token));
      getUserWalletList();
    } else {
      message.error("身份验证失败，请重试");
    }
  }, [token, window.location.search, dispatch]);

  return (
    <div
      className="game_index"
      style={{
        backgroundImage: `url(${require("../../assets/bg.png")})`,
      }}
    ></div>
  );
};

export default GameIndex;
