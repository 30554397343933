import "./index.less";
import { useEffect, useState } from "react";
import { message } from "antd";

const TipProgress = ({
  endTime,
  gameState,
  inGameEndTime,
  inGameTime,
  waitGameEndTime,
  waitMaxTime,
  bossName,
  init = () => {},
}) => {
  const switchTipProgress = () => {
    switch (gameState) {
      // 等待游戏结束
      case "WaitGameEnd":
        return (
          <>
            <span className="countdown_text">
              等待游戏结束 {parseInt(waitGameEndTime)}S
            </span>
            <div className="countdown_progress">
              <div
                className="progress_value"
                style={{
                  width: `${(parseInt(waitGameEndTime) / waitMaxTime) * 100}%`,
                }}
              >
                <img
                  className="progress_icon"
                  src={require("../../../../assets/icon_countdown.png")}
                  alt=""
                />
              </div>
            </div>
          </>
        );
      // 游戏期间
      case "inGame":
        return (
      <>
        <span className="countdown_text">
          {/* {bossName}来袭中{parseInt(20 - inGameTime)}S */}
          派对进行中 {parseInt(20 - inGameTime)}S
        </span>
        <div className="countdown_progress">
          <div
            className="progress_value"
            style={{
              width: `${(parseInt(20 - inGameTime) / 20) * 100}%`,
            }}
          >
            <img
              className="progress_icon"
              src={require("../../../../assets/icon_countdown.png")}
              alt=""
            />
          </div>
        </div>
      </>
    );
      // 游戏结束，下一轮
      case "GameEnd":
        return (
          <>
            <span className="countdown_text">
              {/* {inGameEndTime}S后开始下一轮 */}
              即将开启下一轮
            </span>
            <div className="countdown_progress">
              <div
                className="progress_value"
                style={{
                  width: `${(inGameEndTime / 7) * 100}%`,
                }}
              >
                <img
                  className="progress_icon"
                  src={require("../../../../assets/icon_countdown.png")}
                  alt=""
                />
              </div>
            </div>
          </>
        );
      // 游戏加载中
      case "gameLoading":
        return "";
      // 数据错误
      // case "GameError":
      //   return (
      //     <span className="countdown_text">
      //       数据错误，派对结果可在消费记录查看
      //     </span>
      //   );
      // 购买期、等待出场
      case "loading":
        return (
          <>
            <span className="countdown_text">派对倒计时 {endTime}S</span>
            <div className="countdown_progress">
              <div
                className="progress_value"
                style={{
                  width: `${(endTime / 30) * 100}%`,
                }}
              >
                <img
                  className="progress_icon"
                  src={require("../../../../assets/icon_countdown.png")}
                  alt=""
                />
              </div>
            </div>
          </>
        );
      default:
        return "";
    }
  };
  const initGame = async () => {
    await init();
  };
  useEffect(() => {
    if (gameState === "WaitGameEnd" && waitGameEndTime === 1) initGame();
    if (gameState === "GameEnd") initGame();
  }, [gameState]);

  return <div className="countdown">{switchTipProgress()}</div>;
};

export default TipProgress;
