import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("token") ?? "", // undefined
  isZiDongDuiHuan: localStorage.getItem("isZiDongDuiHuan") ?? undefined,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken: (state, action) => {
      if (action.payload) {
        state.token = action.payload;
        localStorage.setItem("token", action.payload);
      } else {
        localStorage.removeItem("token");
      }
    },
    setIsZiDongDuiHuan: (state, action) => {
      if (
        action.payload !== undefined &&
        action.payload !== null &&
        action.payload !== ""
      ) {
        state.isZiDongDuiHuan = action.payload;
        localStorage.setItem("isZiDongDuiHuan", action.payload);
      } else {
        localStorage.removeItem("isZiDongDuiHuan");
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setIsZiDongDuiHuan } = appSlice.actions;

export default appSlice.reducer;
